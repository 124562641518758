import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import * as styles from './index.module.scss'

import { FooterSections } from './lib/footer-sections';
import MinimalFooter from './minimal';

export const Footer = ({ minimal = false }) => {
  return(
    <footer className={styles.footer}>
      <StaticQuery
        query={graphql`
          query {
            contentfulFooter {
              footerSections {
                references {
                  section
                  sectionTitle {
                    raw
                  }
                  footerLinks {
                    menuItemName
                    menuItemText
                    url
                    image {
                      url
                    }
                  }
                }
              }
              footerSocials {
                references {
                  socialMedia {
                    references {
                      menuItemName
                      menuItemText
                      url
                      image {
                        url
                      }
                    }
                  }
                }
              }
            }
          }            
        `}
        render={data => minimal ? <MinimalFooter data={data} /> : <FooterSections data={data} />}
      />
    </footer>
  )
}
