import React, { useEffect } from "react";

import { Header } from "../header";
import { Footer } from "../footer";
import LiveChat from "../live-chat";
import MinimalHeader from "../header/minimal";
import CallToActionBanner from "../cta-banner";

import * as styles from './index.module.scss';

export const Layout = ({ children, minimal = false, haveLayout = true }) => {
  // A function to allow CSS env for keyboard height
  useEffect(() => {
    if ("virtualKeyboard" in navigator)
      navigator.virtualKeyboard.overlaysContent = true;
  });
  if (haveLayout)
    return (
      <div>
        {!minimal && <Header />}
        {minimal && <MinimalHeader />}
        {!minimal && <CallToActionBanner />}
        <main className={`${styles.container}`}>
          { children }
        </main>
        <Footer minimal={minimal} />
        {!minimal && <LiveChat />}
      </div>
    );
  else
      return (
        <main className={`${styles.container}`}>
          { children }
        </main>
      );
};