import React, { useRef, useState, useEffect } from 'react';

import * as styles from './index.module.scss';

const AccordionItem = ({
    title, 
    children, 
    condensed, 
    hasMetaScopeAttributes = false, 
    simplifiedGreen = false
}) => {
    const [visible, setVisible] = useState(false);
    const contentEl = useRef(null);
    const [contentHeight, setContentHeight] = useState('0px');

    const getTitleClass = () => {
        if (!simplifiedGreen)
            return `${styles.accordionItemTitle} ${styles.accordionItemTitleVerbose} ${(visible) && styles.accordionItemTitleVerboseOpen}`;

        return `${styles.accordionItemTitle} ${(visible) && styles.accordionItemTitleOpen}`;
    }

    const getContentClass = () => {
        if (!simplifiedGreen)
            return (visible) ? styles.accordionItemContent + ' ' + styles.accordionItemContentVerbose + ' ' + styles.accordionItemContentOpen : styles.accordionItemContent + ' ' + styles.accordionItemContentVerbose;
        
        return (visible) ? styles.accordionItemContent + ' ' + styles.accordionItemContentOpen : styles.accordionItemContent;
    }

    useEffect(() => {
        const handleResize = () => {
            if (contentEl.current)
                setContentHeight(`calc(${contentEl.current.clientHeight}px + 2em)`);
        };

        const resizeObserver = new ResizeObserver(() => handleResize());
        if (contentEl.current)
            resizeObserver.observe(contentEl.current);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
            if (contentEl.current)
                resizeObserver.unobserve(contentEl.current);
        };
    }, [contentEl, visible]);

    return (
        <li className={`${styles.accordionItem} ${condensed ? styles.small : ''}`} {...(hasMetaScopeAttributes ? { itemScope: true, itemType: "http://schema.org/Question" } : {})}>
            <div 
                className={getTitleClass()}
                {...(hasMetaScopeAttributes ? { itemProp: "name" } : {})}
                onClick={() => setVisible(!visible)}
            >
                <span {...(hasMetaScopeAttributes ? { itemProp: "text" } : {})}>{title}</span>
            </div>
            <div 
                className={getContentClass()} 
                {...(hasMetaScopeAttributes ? { itemProp: "acceptedAnswer" } : {})} 
                style={{
                    height: visible ? contentHeight : '0px',
                    padding: visible ? '1rem' : '0 1rem'
                }}
            >
                <div {...(hasMetaScopeAttributes ? { itemProp: "text" } : {})} ref={contentEl} className={styles.accordionItemChildren}>
                    {children}
                </div>
            </div>
        </li>
    );
}

export default AccordionItem;